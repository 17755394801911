<template>
  <v-sheet width="100%" height="500px" color="grey-darken-4" class="d-flex align-center justify-center" style="flex-direction: column;">
    <div class="text-h4" style="font-weight: 500; word-spacing: 20px !important;">C R V D</div>
    <div class="text-body-1">Memories that you can {{ memoryprompts[currentmemory] }}</div>
  </v-sheet>

  <v-sheet width="100%" height="360px" color="grey-darken-3" class="d-flex align-center justify-center" style="flex-direction: column;">
    <div class="text-h4">What is CRVD?</div>
    <div class="text-body-1">CRVD is a place where Memories are saved to be shared with others.</div>
    <div class="text-body-1">The Memories are crafted using words, images, and video provided by you into something special.</div>
    <div class="text-body-1">Then the Memory is CRVD into Memory Items that can be worn and shared, allowing you access to your special Memory with a simple scan of the code.</div>
  </v-sheet>

  <v-sheet width="100%" height="600px" color="grey-darken-4" class="d-flex align-center justify-center" style="flex-direction: column;">
    <div class="text-h4" style="margin: 10px auto;">How does it work?</div>
    <div class="text-body-1">Nearly every phone these days has a built in way to read QR Codes, but those that don't can always download an app.</div>
    <div class="text-body-1" style="margin: 0 auto 10px auto;">Then just scan the code:</div>
    <v-img width="164" max-height="164" :src="require('@/assets/promqrcode.png')" />
    <div class="text-h6" style="margin: 10px auto;">Or click one of the links below:</div>
    <v-btn variant="tonal" style="margin: 10px auto;" link to="/me/crvd0001">Learn How CRVD Works</v-btn>
    <v-btn variant="tonal" style="margin: 10px auto;" link to="/me/crvdpets">CRVD For Pets</v-btn>
    <v-btn variant="tonal" style="margin: 10px auto;" link to="/me/crvd0002">Share Your Memory in Pictures with CRVD</v-btn>
  </v-sheet>

  <v-sheet v-if="!subscribed" width="100%" height="360px" color="grey-darken-3" class="d-flex align-center justify-center" style="flex-direction: column;">
    <div class="text-h6 text-center" >We are always searching for the best items to hold your Memories</div>
    <div class="text-h6 text-center" >If you would like updates on our adventure, join our mailing list:</div>
    <v-card width="80%" variant="outline">
      <v-text-field v-model="subscribeemail" style="padding:10px" variant="outlined" label="Email Address" />
      <v-card-actions>
        <v-spacer />
        <v-btn variant="tonal" @click="submitEmail">Subscribe</v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>

  <v-sheet v-if="subscribed" width="100%" height="360px" color="grey-darken-4" class="d-flex align-center justify-center" style="flex-direction: column;">
    <div class="text-h4 text-center">Thanks for subscribing!</div>
  </v-sheet>

  <v-sheet width="100%" height="360px" color="grey-darken-3" class="d-flex align-center justify-center" style="flex-direction: column;">
    <div class="text-h6">CRVD is a service provided by Dan's Tech Services</div>
    <div class="text-body-1">7740 River Rd</div>
    <div class="text-body-1">Marine City, MI 48039</div>
    <div class="text-body-1">810-328-3419</div>
    <div class="text-body-1">support@crvd.us</div>
  </v-sheet>
</template>

<script>

export default{
  name: 'HomeView',

  data : function(){
    return{
      memoryprompts : ['wear', 'share'],
      currentmemory : 0,
      subscribeemail : '',
      subscribed : false,
      contestemail : '',
      contestguess : '',
      guessed : false,
      images : [
        {id : 0, imgurl : 'memorycore.png'},
        {id : 1, imgurl : 'memoryband-gold.png'},
        {id : 2, imgurl : 'bone-silver.png'},
        {id : 3, imgurl : 'heart-rosegold.png'}
      ]
    }
  },

  mounted: function(){
    this.recordAction("Home Page Visited");
    setInterval(() => {
      this.updateMemory();
    }, 3000);
  },

  methods:{
    recordAction(action){
      fetch("https://crvd.us:2100/api/shopaction", { method: "POST", headers : { data : JSON.stringify( { productid : -1, action : action } ) } });
    },
    updateMemory(){
      if(this.currentmemory+1 == this.memoryprompts.length) this.currentmemory = 0;
      else this.currentmemory++;
    },
    submitEmail(){
      if(!this.subscribeemail || this.subscribeemail.length < 5) return;

      fetch("https://crvd.us:2100/api/addemailsubscriber", { method: "POST", headers : { data : this.subscribeemail }})
        .then(res => res.json())
        .then(res => {
          if(res.results != 'Failed'){
            this.subscribed = true;
          }
      });
    },
    submitGuess(){
      if(!this.contestemail || this.contestemail.length < 5 || this.contestguess.length < 2) return;

      fetch("https://crvd.us:2100/api/addcontestguess", { method: "POST", headers : { data : JSON.stringify( {email : this.contestemail, guess : this.contestguess}) }})
        .then(res => res.json())
        .then(res => {
          if(res.results != 'Failed'){
            this.guessed = true;
          }
      });
    }
  }
}
</script>

<style>

</style>