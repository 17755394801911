<template>
  <v-app-bar color="grey-darken-4" flat >
    <v-spacer />
    <v-btn link to="/" variant="text">
      <v-btn-text>Home</v-btn-text>
    </v-btn>
    <v-btn link to="/memories" variant="text">
      <v-btn-text>Memories</v-btn-text>
    </v-btn>
    <v-btn link to="/shop" variant="text">
      <v-btn-text>Memory Items (Shop)</v-btn-text>
    </v-btn>
  </v-app-bar>
</template>

<script>

export default {
  name: 'NavBar'
}
</script>